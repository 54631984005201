import React, { useEffect, useRef, useState } from "react"
import Footer from "../components/footer";
import Header from "../components/header";
import { getTheme } from "../functions/theme";
import AnimatedLogoBtn from "./animated-logo-btn/animated-logo-btn";
import HomeOurServices from "./home-components/home-our-services";
import HomeRecentWorks from "./home-components/home-recent-works";
import HomeSubtitle from "./home-components/home-subtitle";
import HomeTitle from "./home-components/home-title";

// export const homeSector = {
//   width: '100vw',
//   height: '100vh',
// }

const HomePage = () => {

  const targetRef: React.MutableRefObject<undefined> = useRef();

  const [targetElement, setElement] = useState();

  const currentTheme: 'light' | 'dark' = getTheme();

  useEffect(() => {
    setElement(targetRef.current);
  }, []);

  // const background: BannerLayer = {
  //   image:
  //     'https://s3-us-west-2.amazonaws.com/s.cdpn.io/105988/banner-background.jpg',
  //   translateY: [0, 50],
  //   opacity: [1, 0.3],
  //   scale: [1.05, 1, 'easeOutCubic'],
  //   shouldAlwaysCompleteAnimation: true,
  // };

  // const headline: BannerLayer = {
  //   translateY: [0, 30],
  //   scale: [1, 1.05, 'easeOutCubic'],
  //   shouldAlwaysCompleteAnimation: true,
  //   expanded: false,
  //   children: (
  //     <div className="absolute inset-0 flex items-center justify-center">
  //       <h1 className="text-6xl font-thin text-white md:text-8xl">
  //         Hello World!
  //       </h1>
  //     </div>
  //   ),
  // };

  // const foreground: BannerLayer = {
  //   image:
  //     'https://s3-us-west-2.amazonaws.com/s.cdpn.io/105988/banner-foreground.png',
  //   translateY: [0, 15],
  //   scale: [1, 1.1, 'easeOutCubic'],
  //   shouldAlwaysCompleteAnimation: true,
  // };

  // const gradientOverlay: BannerLayer = {
  //   opacity: [0, 1.2],
  //   shouldAlwaysCompleteAnimation: true,
  //   expanded: false,
  //   children: (
  //     <div className="absolute inset-0 " style={{
  //       background: `linear-gradient(rgb(242, 240, 242) 50%, rgba(34,34,34,0.8) 20%, rgba(31,31,31,1) 30%) 50%`
  //     }} />
  //   ),
  //   targetElement: { targetElement } as any,
  //   easing: 'easeInQuad'
  // };

  return (
    <>
      <Header currentPageTheme={currentTheme}/>
      <div className="reverse-bg-zone-trigger dark:bg-black dark:text-white" id="reverse-bg-zone-trigger">
        <HomeTitle/>
        <HomeSubtitle/>
      </div>
      <AnimatedLogoBtn/>
      {/* <ParallaxBanner layers={[gradientOverlay]}> */}
      <div className="bg-black reverse-color-zone-trigger text-cardinal-white dark-bg-zone" id="reverse-color-zone-trigger">
        <div className="py-20"></div>
        <HomeRecentWorks/>
        {/* </ParallaxBanner> */}
        <div className="py-20"></div>
        <HomeOurServices targetRef={targetRef}/>
      </div>
      <Footer/>
    </>
  );
}

export default HomePage;