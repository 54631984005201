import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { getTheme } from "../../functions/theme";
import HomeSecondaryTitle from "./home-secondary-title";

const HomeSubtitle = () => {

  // --- Static query to get page texts
  const staticQuery: any = useStaticQuery(query);
  const _pageText: { aboutTitle: string, about: string } = staticQuery.strapiHome;
  const [pageText, setPageText] = useState<{aboutTitle: string, about: string}>(_pageText);

  // Getting theme
  const currentTheme: 'light' | 'dark' = getTheme();

  return (

    <div className="flex flex-col items-center justify-center min-h-screen px-4 lg:px-32 md:px-16 3xl:w-[72%]">
      <HomeSecondaryTitle smallTitle={pageText?.aboutTitle} mainTitle={pageText?.about}/>
      <div className="flex w-full h-8 mt-8 lg:h-12 lg:mt-20">
        <Link to="/manifest">
          <div className={`${currentTheme == 'light' ? 'hover:bg-black text-black hover:text-cardinal-white border-black' : 'hover:bg-cardinal-white border-cardinal-white text-cardinal-white hover:text-black'} font-bold transition-all flex items-center border-2 justify-center h-full uppercase text-xs w-40 rounded-full lg:w-48 lg:text-sm`}>read our manifesto</div>
        </Link>
      </div>
    </div>

  )

}

export default HomeSubtitle;

export const query = graphql`
  query {
    strapiHome {
      aboutTitle
      about
    }
  }
`;