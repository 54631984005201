import React, { useEffect } from "react"
// import { ParallaxProvider } from "react-scroll-parallax"
import DefaultAppView from "../app-view/default-app-view"
import { SEO } from "../components/seo"
import { checkTheme } from "../functions/theme"
import { isMobileDevice } from "../functions/utils"
import HomePage from "./home"

const IndexPage = () => {

  useEffect(() => {
    //Da richiamare quindi portare fuori da useEffect
    checkTheme();

    /** Cosa Mistica senza prove del suo funzionamento */
    const isMobile: boolean = isMobileDevice();

    //Copiato da freename
    if (isMobile) {
      const vh: number = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
    /** Fine cosa Mistica senza prove del suo funzionamento */
  });

  return (
      <DefaultAppView>
        
        <HomePage/>

      </DefaultAppView>
  )

}

export default IndexPage

export const Head = () => (
  <SEO />
)